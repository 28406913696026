import { useEffect } from 'react';

import metal1 from '../Images/Metal/aabado espejo1.jpg';
import metal2 from '../Images/Metal/corte laser p.jpg';
import metal3 from '../Images/Metal/corte laser.jpg';
import metal4 from '../Images/Metal/corte.jpg';
import metal5 from '../Images/Metal/corte1.jpg';
import metal6 from '../Images/Metal/corte2.jpg';
import metal7 from '../Images/Metal/inox satinado 1.jpg';
import metal8 from '../Images/Metal/inox4.jpg';
import metal9 from '../Images/Metal/perf laser.jpg';
import metal10 from '../Images/Metal/portada corte.jpg';

import madera from '../Images/Madera/closet.jpg';
import madera1 from '../Images/Madera/cuadros mad.jpg';
import madera2 from '../Images/Madera/enchapado.jpg';
import madera3 from '../Images/Madera/insta.jpg';
import madera4 from '../Images/Madera/madera 1.jpg';
import madera5 from '../Images/Madera/madera cnc.jpg';
import madera6 from '../Images/Madera/madera cnc1.jpg';
import madera7 from '../Images/Madera/madera varias.jpg';
import madera8 from '../Images/Madera/madera2.jpg';
import madera9 from '../Images/Madera/madera3.jpg';
import madera10 from '../Images/Madera/madera4.jpg';
import madera11 from '../Images/Madera/marcomad.jpg';
import madera12 from '../Images/Madera/mdf apilado.jpg';
import AOS from 'aos';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

export default function Galeria() {

  useEffect(() => {
    AOS.init({ duration: 2800 })
  })
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 400,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <div className='py-6 bg-gray-600 rounded-tr-3xl text-center mt-2' data-aos="zoom-in">
        <h1 className=' tracking-widest text-2xl md:text-4xl font-semibold text-gray-300 font-AileronsThin font normal-case'>
          Galeria
        </h1>
      </div>
      <div className='w-full' style={{background: '#F8B636'}}>
        <div className='relative w-5/6 mx-auto'>

          <h2 className=' text-center text-2xl py-10 font-AileronsThin font-bold'>

            Procesos en metal
          </h2>
          <Slider {...settings}>
            <div className=' ' >
              <img src={metal1} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=' '>
              <img src={metal2} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=' '>
              <img src={metal3} className='rounded-xl   px-1 hover:px-0' />
            </div>

            <div className=''>
              <img src={metal4} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=''>
              <img src={metal5} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=''>
              <img src={metal6} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=' '>
              <img src={metal7} className='rounded-xl   px-1 hover:px-0' />
            </div>

            <div className=''>
              <img src={metal8} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=''>
              <img src={metal9} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=''>
              <img src={metal10} className='rounded-xl   px-1 hover:px-0' />
            </div>
          </Slider>
          {/* 
<div data-aos="zoom-in" className='px-1 pt-4 pb-8 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio4} className='rounded' />
</div>
<div data-aos="zoom-in" className='px-1 pt-4 pb-8 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio5} className='rounded' />
</div>
<div data-aos="zoom-in" className='px-1 pt-4 pb-8 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio6} className='rounded' />
</div>

<div data-aos="zoom-in" className='px-10 py-10 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio6} className='rounded' />
<h1 className=' font-AileronsUltraLight font-semibold text-xl'>Madera enchapado</h1>
<p>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.</p>
</div>
<div data-aos="zoom-in" className='px-10 py-10 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio6} className='rounded' />
<h1 className=' font-AileronsUltraLight font-semibold text-xl'>Madera maquinado</h1>
<p>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.</p>
</div> */}

          <h2 className=' text-center text-2xl py-10 font-AileronsThin font-bold'>

            Procesos en madera
          </h2>
          <Slider {...settings}>
            <div className=' ' >
              <img src={madera} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=' '>
              <img src={madera1} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=' '>
              <img src={madera2} className='rounded-xl   px-1 hover:px-0' />
            </div>

            <div className=''>
              <img src={madera3} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=''>
              <img src={madera4} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=''>
              <img src={madera5} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=' '>
              <img src={madera6} className='rounded-xl   px-1 hover:px-0' />
            </div>

            <div className=''>
              <img src={madera7} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=''>
              <img src={madera8} className='rounded-xl   px-1 hover:px-0' />
            </div>
            <div className=''>
              <img src={madera9} className='rounded-xl   px-1 hover:px-0' />
            </div>
          </Slider>
          {/* 
<div data-aos="zoom-in" className='px-1 pt-4 pb-8 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio4} className='rounded' />
</div>
<div data-aos="zoom-in" className='px-1 pt-4 pb-8 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio5} className='rounded' />
</div>
<div data-aos="zoom-in" className='px-1 pt-4 pb-8 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio6} className='rounded' />
</div>

<div data-aos="zoom-in" className='px-10 py-10 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio6} className='rounded' />
<h1 className=' font-AileronsUltraLight font-semibold text-xl'>Madera enchapado</h1>
<p>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.</p>
</div>
<div data-aos="zoom-in" className='px-10 py-10 shadow-md shadow-gray-400 rounded-lg hover:bg-gray-500 hover:shadow-2xl hover:shadow-gray-800 hover:text-white  '>
<img src={portafolio6} className='rounded' />
<h1 className=' font-AileronsUltraLight font-semibold text-xl'>Madera maquinado</h1>
<p>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.</p>
</div> */}
        </div>
      </div>

    </>
  );
}