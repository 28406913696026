import './App.css';
import Cards from './Components/Cards';
import Navbar from './Components/Header';
import Footers from './Components/Footer';
import Example from './Components/services';
import Eleccion from './Components/Eleccion';
import InitOne from './Components/initial';
import ExampleTwo from './Components/headless';
import Nosotros from './Components/Nosotros/Nosotros'
import avatarN from './Images/Principal/planta1.jpg';
import { Route, Routes } from "react-router-dom"
import ServicioUno from './Components/Servicios/ServicioUno';
import ServicioDos from './Components/Servicios/ServicioDos';
import Contact from './Components/Contact/contact';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Galeria from './Components/Galeria';

import 'leaflet/dist/leaflet.css';
import UnderConstruction from './Components/Helpers/UnderConstruction';

function App() {

  return (
    <>
      <Navbar ></Navbar>

      <Routes>
        <Route path="/" element={
          <>
            <InitOne />
            <Example />
            <Eleccion />
            <Galeria/>
          </>} />
        <Route path="/nosotros" element={<><UnderConstruction  /></>} />
        <Route path="/serviciouno" element={<ServicioUno />} />
        <Route path="/serviciodos" element={<ServicioDos />} />
        <Route path="/portafolio" element={<><UnderConstruction  /></>} />

        <Route path="/contact" element={<Contact />} />
      </Routes>

      <FloatingWhatsApp
        phoneNumber='4424717548'
        accountName='Atencion a clientes'
        avatar={avatarN}
        chatMessage='¡Hola!
        ¿Como puedo ayudarte?'
        statusMessage='Respuestas en menos de 1 hora'
        placeholder='Escribe un mensaje...'
        allowClickAway
        allowEsc
        st
      />


      <Footers></Footers>

    </>
  );
}

export default App;
