// {// import { Link, useMatch, useResolvedPath } from "react-router-dom"
// // import { Fragment, useState } from 'react'
// // import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
// // import {
// //   ArrowPathIcon,
// //   Bars3Icon,
// //   ChartPieIcon,
// //   CursorArrowRaysIcon,
// //   FingerPrintIcon,
// //   SquaresPlusIcon,
// //   XMarkIcon,
// //   CalculatorIcon,
// //   CircleStackIcon,
// //   CogIcon,
// //   CubeTransparentIcon
// // } from '@heroicons/react/24/outline'
// // import logomaquila from '../Images/metalwise.png';
// // import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

// // const products = [
// //   {
// //     name: 'TRUMATIC L3050',
// //     description: 'Tipo de máquina: Cortador láser (CO2)',
// //     to: '/serviciouno',
// //     icon: ChartPieIcon,
// //   },
// //   { name: 'TRUMPF TUBE 5000', description: 'Speak directly to your customers', to: '#', icon: CursorArrowRaysIcon },
// //   { name: 'GRUA VIAJER SEMSA', description: 'Your customers’ datLink will be safe and secure', to: '#', icon: FingerPrintIcon },
// //   { name: 'ROBOT PAR SOLDADURA MIG', description: 'CelulLink de soldadurLink robotizadLink Motofil', to: '#', icon: SquaresPlusIcon },
// //   { name: 'PINTUR ELECTROESTATICA', description: 'Tren de pinturLink electroestaticLink Euroimpianti', to: '#', icon: CalculatorIcon },
// //   { name: 'SECCIONADOR GABBIANI PT 80', description: 'Build strategic funnels that will convert', to: '#', icon: CircleStackIcon },
// //   { name: 'ENCHAPADOR SCM STEFANI MD', description: 'Build strategic funnels that will convert', to: '#', icon: CogIcon },
// //   { name: 'CNC MORBIDELLI M100F', description: 'Build strategic funnels that will convert', to: '#', icon: CubeTransparentIcon },
// // ]
// // const callsToAction = [
// //   { name: 'Contacto', to: '/Contact', icon: PhoneIcon },
// // ]

// // function classNames(...classes) {
// //   return classes.filter(Boolean).join(' ')
// // }

// // export default function Example() {
// //   const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

// //   return (
// //     <header className="bg-white">
// //       <nav className="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-8" aria-label="Global">
// //         <div className="flex lg:flex-1">
// //           <Link to="/" className="-m-1.5 p-2">
// //             <span className="sr-only"></span>
// //             <img className="h-16 w-auto" src={logomaquila} alt="" />
// //           </Link >
// //         </div>
// //         <div className="flex lg:hidden">
// //           <button
// //             type="button"
// //             className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
// //             onClick={() => setMobileMenuOpen(true)}
// //           >
// //             <span className="sr-only">Open main menu</span>
// //             <Bars3Icon className="h-6 w-6" aria-hidden="true" />
// //           </button>
// //         </div>

// //         <Popover.Group className="hidden lg:flex lg:gap-x-12">
// //         <Link to="/nosotros" className=" font-AileronsThin text-xl font-semibold">
// //             NOSOTROS
// //           </Link >
// //           <Popover className="relative">


// //             <Popover.Button className="flex items-center gap-x-1 font-AileronsThin text-xl font-semibold">
// //               SERVICIOS
// //               <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
// //             </Popover.Button>

// //             <Transition
// //               as={Fragment}
// //               enter="transition ease-out duration-200"
// //               enterFrom="opacity-0 translate-y-1"
// //               enterTo="opacity-100 translate-y-0"
// //               leave="transition ease-in duration-150"
// //               leaveFrom="opacity-100 translate-y-0"
// //               leaveTo="opacity-0 translate-y-1"
// //             >
// //               <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
// //                 <div className="p-4">
// //                   {products.map((item) => (
// //                     <div
// //                       key={item.name}
// //                       className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
// //                     >
// //                       <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
// //                         <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
// //                       </div>
// //                       <div className="flex-auto">
// //                         <Link to={item.to} className="block font-semibold text-gray-900">
// //                           {item.name}
// //                           <span className="absolute inset-0" />
// //                         </Link >
// //                         <p className="mt-1 text-gray-600">{item.description}</p>
// //                       </div>
// //                     </div>
// //                   ))}
// //                 </div>
// //                 <div className=" divide-gray-900/5 bg-gray-50">
// //                   {callsToAction.map((item) => (
// //                     <Link
// //                       key={item.name}
// //                       to={item.to}
// //                       className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
// //                     >
// //                       <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
// //                       {item.name}
// //                     </Link >
// //                   ))}
// //                 </div>
// //               </Popover.Panel>
// //             </Transition>
// //           </Popover>


// //           <Link to="#" className="font-AileronsThin text-xl font-semibold">
// //             PORTAFOLIO
// //           </Link >
// //           <Link to="/Contact" className="font-AileronsThin text-xl font-semibold">
// //             CONTACTO
// //           </Link >
// //         </Popover.Group>
// //         <div className="hidden lg:flex lg:flex-1 lg:justify-end">
// //           <Link to="#" className="text-sm font-semibold leading-6 text-gray-900">
// //             Log in <span aria-hidden="true">&rarr;</span>
// //           </Link >
// //         </div>
// //       </nav>
// //       <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
// //         <div className="fixed inset-0 z-10" />
// //         <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
// //           <div className="flex items-center justify-between">
// //             <Link to="#" className="-m-1.5 p-1.5">
// //               <span className="sr-only">Your Company</span>
// //               <img
// //                 className="h-8 w-auto"
// //                 src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
// //                 alt=""
// //               />
// //             </Link >
// //             <button
// //               type="button"
// //               className="-m-2.5 rounded-md p-2.5 text-gray-700"
// //               onClick={() => setMobileMenuOpen(false)}
// //             >
// //               <span className="sr-only">Close menu</span>
// //               <XMarkIcon className="h-6 w-6" aria-hidden="true" />
// //             </button>
// //           </div>
// //           <div className="mt-6 flow-root">
// //             <div className="-my-6 divide-y divide-gray-500/10">
// //               <div className="space-y-2 py-6">
// //                 <Disclosure as="div" className="-mx-3">
// //                   {({ open }) => (
// //                     <>
// //                       <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
// //                         SERVICIOS
// //                         <ChevronDownIcon
// //                           className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
// //                           aria-hidden="true"
// //                         />
// //                       </Disclosure.Button>
// //                       <Disclosure.Panel className="mt-2 space-y-2">
// //                         {[...products, ...callsToAction].map((item) => (
// //                           <Link
// //                             to={item.to}
// //                             className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
// //                           >
// //                             {item.name}
// //                           </Link>
// //                         ))}
// //                       </Disclosure.Panel>
// //                     </>
// //                   )}
// //                 </Disclosure>
// //                 <Link
// //                   to="#"
// //                   className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
// //                 >
// //                   Features
// //                 </Link >
// //                 <Link
// //                   to="#"
// //                   className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
// //                 >
// //                   Marketplace
// //                 </Link >
// //                 <Link
// //                   to="#"
// //                   className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
// //                 >
// //                   Company
// //                 </Link >
// //               </div>
// //               <div className="py-6">
// //                 <Link
// //                   to="#"
// //                   className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
// //                 >
// //                   Log in
// //                 </Link >
// //               </div>
// //             </div>
// //           </div>
// //         </Dialog.Panel>
// //       </Dialog>
// //     </header>
// //   )
// // 
// }

import { Link, useMatch, useResolvedPath } from "react-router-dom"
import { Children, Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import logomaquila from '../Images/Header/MGM24 logo 2.png';
import laserlogo from '../Images/Header/maquina-de-corte-por-laser.png';
import robotlogo from '../Images/Header/brazo-robotico.png';
import pinturalogo from '../Images/Header/pistola-rociadora.png';
import maderalogo from '../Images/Header/madera.png';

import banderamx from '../Images/Banderas/mexico.png';
import banderausa from '../Images/Banderas/estados-unidos.png';
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next';



export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)


  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const products = [
    {
      name: t('Service1'),
      to: '/serviciouno',
      icon: laserlogo,

    },
    {
      name: t('Service2'),
      to: '/serviciodos',
      icon: robotlogo
    },
    {
      name: t('Service3'),
      to: '#',
      icon: pinturalogo
    },
    {
      name: t('Service4'),
      to: '#',
      icon: maderalogo
    },
  ]
  const callsToAction = [
    { name: 'Contacto', to: '/Contact', icon: PhoneIcon },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  return (
    <header className="bg-gray-50 opacity-99 stick sm:fixed top-0 z-50 w-full">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-1 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className=" mx-auto py-auto">
            <span className="sr-only"></span>
            <img className="h-20 mx-auto sm:h-20 w-auto" src={logomaquila} alt="" />
          </Link >
        </div>

        <span></span>

        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Link to="/nosotros" className="flex items-center gap-x-1 font-AileronsThin text-xl font-semibold">
            {t('NOSOTROS')}
            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />

          </Link >
          <Popover className="relative">


            <Popover.Button className="flex items-center gap-x-1 font-AileronsThin text-xl font-semibold">
              {t('SERVICIOS')}
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-100"
                    >
                      <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <img src={item.icon} />
                      </div>
                      <div className="flex-auto">
                        <Link to={item.to} className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link >
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className=" divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {item.name}
                    </Link >
                  ))}
                </div> */}
              </Popover.Panel>
            </Transition>
          </Popover>


          <Link to="/portafolio" className="flex items-center gap-x-1 font-AileronsThin text-xl font-semibold">
            {t('PORTAFOLIO')}
            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />

          </Link >
          <Link to="/Contact" className="flex items-center gap-x-1 font-AileronsThin text-xl font-semibold">
            {t('CONTACTO')}
            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </Link >
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <div className=" items-center">
            <h1 className=" text-center">
            {t('Idioma')}
            </h1>
            <button onClick={() => changeLanguage('es')} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
              <img className="h-8" src={banderamx} />

            </button>
            <button onClick={() => changeLanguage('en')} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
              <img className="h-8" src={banderausa} />

            </button>
          </div>

        </div>
        <div className="flex lg:hidden px-5">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>

      <Dialog as="div" className="lg:hidden " open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src=""
                alt=""
              />
            </Link >
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
              <Link
                  to="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t('NOSOTROS')}
                </Link >
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      {t('SERVICIOS')}
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {/* {[...products, ...callsToAction].map((item) => ( */}
                        {[...products].map((item) => (
                          <Link
                            to={item.to}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                
                <Link
                  to="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
              {t('PORTAFOLIO')}
                </Link >
                <Link
                  to="/portafolio"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
              {t('CONTACTO')}
                </Link >
              </div>
              <div className="lg:flex lg:flex-1 lg:justify-end">
                <div className=" items-center ">
                  <h1 className=" text-center">
                  {t('Idioma')}
                  </h1>
                  <div className="flex justify-center gap-4">
                  <button onClick={() => changeLanguage('es')} className="">
                    <img className="h-12" src={banderamx} onClick={() => setMobileMenuOpen(false)}/>

                  </button>
                  <button onClick={() => changeLanguage('en')} className="">
                    <img className="h-12" src={banderausa} onClick={() => setMobileMenuOpen(false)} />

                  </button>
                  </div>
                  
                </div>

              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
