import adetalle from '../Images/Principal/adetalle.jpg';
import portada from '../Images/Principal/portada corte.jpg';
import robot from '../Images/Principal/arobot.jpg';
import cabina from '../Images/Principal/cabinapint.jpg';
import wood from '../Images/Principal/wood.jpg';
import madera from '../Images/Principal/madera.jpg';
import { ArrowLongRightIcon, SquaresPlusIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TextSlider from './TextSlider';
import { useTranslation } from 'react-i18next';

const links = [
  { name: 'Naves Industriales', href: '#' },
  { name: 'Colaboradores', href: '#' },
  { name: 'Nuestros Valores', href: '#' },
  { name: 'Contacto', href: '#' },
]
const stats = [
  { name: 'Queretaro', value: '1' },
  { name: 'Colaboradores Full-Time', value: '150+' },
  { name: 'Horas a la semana', value: '48+' },
  { name: 'Paid time off', value: 'Unlimited' },
]


const images = [
  "https://via.placeholder.com/800x400",
  "https://via.placeholder.com/800x400",
  "https://via.placeholder.com/800x400",
];

export default function InitOne() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (

    <>
      {/*     
    <div className="relative w-full">
      
      <Carousel className='absolute inset-0 -z-10 h-full w-full object-cover object-right sm:object-center'>
        <img src={adetalle} alt="..." />
        <img src={portada} alt="..." />
        <img src={robot} alt="..." />
        <img src={cabina} alt="..." />
        <img src={wood} alt="..." />
        <img src={madera} alt="..." />
      </Carousel>

      <div className="grid grid-rows-3  md:grid-rows-5 w-full">
        
        <div
          className="row-span-1 row-start-2 md:row-start-3 md:row-span-1 md:col-span-1 ml-10 sm:ml-20 md:w-4/6 bg-black bg-opacity-35 rounded-md"
        >
          <h2
            className="text-2xl font-AileronsLight text-white md:text-5xl">
            Bienvenido a MGM
          </h2>

          
        </div>

        <div className="row-start-4 col-start-2 mx-5 sm:mx-10 sm:my-auto sm:py-1 sm:px-3 sm:shadow-2xl shadow-slate-950 rounded-lg bg-gray-100 bg-opacity-15 ">
          <input className=' text-xs w-9/12 sm:w-11/12 rounded-md border-spacing-0 font-AileronsLight font-semibold border-gray-600' placeholder=" Suscribete"></input>

          <NewspaperIcon className='h-6 text-white' onClick={(e) => { console.log("Hola Mundo") }} />

        </div>
      </div>


    </div> */}

      <div className="flex flex-col items-center justify-center h-full bg-gray-100">
        <div className="relative min-h-lvh:">
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={2000}
          >

            <div>
              <img src={adetalle} alt='' />
              
            </div>
            <div>
              <img src={portada} alt='' />
              {/* <div className="absolute top-1/2 w-3/5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center p-3 rounded-2xl  bg-gray-700 bg-opacity-35">
                <h1 className="text-xl md:text-4xl font-bold text-white">Soluciones a la medida en el ramo Metalurgia</h1>
              </div> */}
            </div>
            <div>
              <img src={wood} alt='' />
              {/* <div className="absolute top-1/2 w-3/5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center p-3 rounded-2xl  bg-gray-700 bg-opacity-35">
                <h1 className="text-xl md:text-4xl font-bold text-white">Soluciones a la medida en el ramo automotriz</h1>
              </div> */}
            </div>
            <div>
              <img src={robot} alt='' />
              {/* <div className="absolute top-1/2 w-3/5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center p-3 rounded-2xl  bg-gray-700 bg-opacity-35">
                <h1 className="text-xl md:text-4xl font-bold text-white">Soluciones a la medida en el ramo </h1>
              </div> */}
            </div>

          </Carousel>
          <div className="absolute top-6 md:top-1/4 md:w-2/8 left-1/4 transform -translate-x-1/2 -translate-y-1/2 p-0 md:p-4 rounded-2xl  bg-gray-700 bg-opacity-45">
            <h1 className="text-lg md:text-3xl font-bold text-white md:mb-2 border-b-2 border-red-600">{t('Bienvenido')} MGM24</h1>
            {/* <h1 className="text-xl md:text-4xl font-bold text-white">Soluciones a la medida en el ramo industrial</h1> */}
          </div>
          <div className="absolute top-1/2 w-4/5 md:w-2/5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center p-1 md:p-3 rounded-2xl  bg-gray-700 bg-opacity-55">
                {/* <h1 className="text-xl md:text-4xl font-bold text-white">Soluciones a la medida en el ramo industrial</h1> */}
                <div className=" flex items-center justify-center text-white">
                  <TextSlider />
                </div>
              </div>

        </div>
      </div>



    </>
  )
}
