import calidad from '../Images/Eleccion/calidad.png';
import altoybajo from '../Images/Eleccion/altoybajo.png';
import optimizacion from '../Images/Eleccion/optimizacion2.png';
import prototipos from '../Images/Eleccion/prototipo.png';
import certficacion from '../Images/Eleccion/certificacion.png';
import fsc from '../Images/Eleccion/fsc.png';
import puntual from '../Images/Eleccion/puntual.png';
import fondoeleccion from '../Images/Eleccion/9205963.jpg'

export default function Elegirnos() {
    return (
        <>
            <div className="relative isolate overflow-hidden mt-2 " >
                <div className='relative py-6 bg-gray-600 rounded-tr-3xl text-center' data-aos="zoom-in">
                    <h1 className='tracking-widest text-2xl md:text-4xl font-semibold text-gray-300 font-AileronsThin font normal-case'>
                        ¿Por qué elegirnos?
                    </h1>
                </div>
                <div className=''>
                    <div className=" relative grid grid-cols-2 md:grid-cols-3 gap-y-4 md:gap-y-10 gap-x-6 md:gap-x-20 w-5/6 mx-auto pt-32 pb-24 ">

                        <div className="">
                            <div className=" w-full ">
                                <img src={calidad} className='h-20 mx-auto' />
                            </div>
                            <div className="text-center w-full mt-2 mb-2 font-AileronsThin font-bold">
                                Productos de calidad
                            </div>
                            <div className="bg-gray-500 text-white px-2 lg:w-3/5 mx-auto rounded-lg font-AileronsThin">
                                Contamos con excelente control de calidad en todos nuestros procesos
                            </div>
                        </div>
                        <div className="bg-white  rounded-full py-3 bg-opacity-85 ">
                            <div className=" w-full">
                                <img src={altoybajo} className='h-20 mx-auto' />

                            </div>
                            <div className="text-center w-full mt-2 mb-2 font-AileronsThin font-bold">
                                Produccion en alto y bajo volumen

                            </div>
                            <div className="bg-gray-500 text-white px-2 lg:w-3/5 mx-auto rounded-lg font-AileronsThin">
                                Fabricamos las piezas que sean necesarias segun sus requerimientos
                            </div>
                        </div>
                        <div className=" bg-white  rounded-full py-3 bg-opacity-35">
                            <div className=" w-full">
                                <img src={optimizacion} className='h-20 mx-auto' />

                            </div>
                            <div className="text-center w-full mt-2 mb-2 font-AileronsThin font-bold">
                                Optimizacion de materiales
                            </div>
                            <div className="bg-gray-500 text-white px-2 lg:w-3/5 mx-auto rounded-lg font-AileronsThin">
                                Optimizamos al maximo el uso de los materiales con merma minima
                            </div>
                        </div>
                        <div className=" bg-white  rounded-full py-3 bg-opacity-35">
                            <div className=" w-full">
                                <img src={prototipos} className=' h-20 mx-auto' />

                            </div>
                            <div className="text-center w-full mt-2 mb-2 font-AileronsThin font-bold">
                                Prototipos
                            </div>
                            <div className="bg-gray-500 text-white px-2 lg:w-3/5 mx-auto rounded-lg font-AileronsThin">
                                Realizamos prototipos de alta calidad para sus proyectos
                            </div>
                        </div>
                        <div className=" bg-white  rounded-full py-3 bg-opacity-85">
                            <div className=" w-full">
                                <img src={fsc} className='h-20 mx-auto' />

                            </div>
                            <div className="text-center w-full mt-2 mb-2 font-AileronsThin font-bold">
                                Certificacion FSC
                            </div>
                            <div className="bg-gray-500 text-white px-2 lg:w-3/5 mx-auto rounded-lg font-AileronsThin">
                                Estamos certificados por FSC en madera confirmando que los materiales de base forestal y los productos comprados, etiquetados y vendidos como certificados FSC se originan en bosques manejados responsablemente
                            </div>
                        </div>
                        <div className=" bg-white  rounded-full py-3 bg-opacity-35">
                            <div className=" w-full">
                                <img src={puntual} className='h-20 mx-auto' />

                            </div>
                            <div className="text-center w-full mt-2 mb-2 font-AileronsThin font-bold">
                                Entrega puntual
                            </div>
                            <div className="bg-gray-500 text-white px-2 lg:w-3/5 mx-auto rounded-lg font-AileronsThin">
                                Nos comprometemos a entregar en tiempo y forma lo que nos requieran
                            </div>
                        </div>




                    </div>
                </div>

            </div>
        </>
    );
}