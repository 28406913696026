
import { Footer } from 'flowbite-react';
import { Link } from "react-router-dom"
import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import logomaquila from '../Images/Header/MGM24 logo 2.png'
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter, BsLinkedin } from 'react-icons/bs';




function Footers() {

  const mapaRef = useRef();
  const [mapaInicializado, setMapaInicializado] = useState(false);

  useEffect(() => {
    if (!mapaInicializado) { // Verifica si el mapa ya ha sido inicializado
      const mapa = L.map(mapaRef.current, { scrollWheelZoom: false }).setView([20.5325764, -100.2500421], 13); // Coordenadas de Ramon Garcia
        
      L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGlyZ214IiwiYSI6ImNsdGV4aTk3YjA0dTgyaXJ6Zzg3amV0cGoifQ.Jb6MqOCDL20k3MxYSf6iMA', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 18,
        id: 'mapbox/traffic-day-v2',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'tu_clave_de_api_de_mapbox'
      }).addTo(mapa);

      var century21icon = L.icon({
        iconUrl: 'https://img.icons8.com/?size=100&id=7880&format=png&color=FA5252',
        iconSize: [20, 20]
      });

      L.marker([20.5325764, -100.2500421], { icon: century21icon }).addTo(mapa) // Marcador para el código postal 76246
        .bindPopup('MGM24')
        .openPopup();

      setMapaInicializado(true); // Actualiza el estado para indicar que el mapa ha sido inicializado
    }
  }, []);

  return (
    <Footer container className='mt-10'>
      <div className="w-full grid grid-cols-2 md:grid-cols-6 gap-2">
        <div className=' justify-center'>
          <div className="flex lg:flex-1">
            <Link to="/" className="">
              <span className="sr-only"></span>
              <img className=" h-16 md:h-20 w-auto" src={logomaquila} alt="" />
            </Link >
          </div>
           
        </div>
        <div className=" justify-items-center">
          <div className='col-start-1'>
            <Footer.Title title="Contacto" />
            <Footer.LinkGroup col>
              <Footer.Link href="https://maps.app.goo.gl/GNSuNxHp6BgaQ94T6">Av. Industria Robótica L. 4 Mz. 8 Parque Industrial PYME, 76246 Qro.</Footer.Link>
              <Footer.Link href="#">4421234567</Footer.Link>
            </Footer.LinkGroup>
          </div>





          {/* <div>
              <Footer.Title title="About us" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">Flowbite</Footer.Link>
                <Footer.Link href="#">Tailwind CSS</Footer.Link>
              </Footer.LinkGroup>
            </div>

            <div>
              <Footer.Title title="Legal" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">Privacy Policy</Footer.Link>
                <Footer.Link href="#">Terms &amp; Conditions</Footer.Link>
              </Footer.LinkGroup>
            </div> */}
        </div>
        <div className='w-full col-span-2 md:col-span-4'>
          <div ref={mapaRef} style={{ height: "250px", width: "100%" }} />
        </div>

        <div className=" col-span-2 md:col-span-7">
          <Footer.Divider />
          <div className="mt-4  mx-px md:mx-px flex space-x-6 sm:mt-0 justify-center">
            <Footer.Icon href="#" icon={BsFacebook} />
            <Footer.Icon href="#" icon={BsInstagram} />
            <Footer.Icon href="#" icon={BsLinkedin} />
          </div>
          <Footer.Copyright href="#" by="MGM 24 all rights reserved " year={2024} className='flex justify-center p-4' />

        </div>
      </div>
    </Footer>
  );
}
export default Footers;