import React from 'react';

const UnderConstruction = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-gray-800 bg-gray-100">
            <h1 className="mb-4 text-5xl font-bold">Página en construcción</h1>
            <p className="mb-8 text-xl">Estamos trabajando para brindarte una mejor experiencia. ¡Vuelve pronto!</p>
            <div className="flex items-center justify-center w-12 h-12 mb-4 bg-yellow-500 rounded-full animate-bounce">
                <svg className="w-6 h-6 text-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <path d="M36.068 19.02L3.682 1.958C3.071 1.567 2.5 2.084 2.5 2.826v34.348c0 .742.571 1.26 1.182.868l32.386-17.062c.61-.322.61-1.414 0-1.736z"/>
                </svg>
            </div>
            <p className="text-sm text-gray-600">Gracias por tu paciencia.</p>
        </div>
    );
};

export default UnderConstruction;