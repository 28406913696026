import { useEffect } from 'react';
import laserlogo from '../Images/trumatic2.jpg';
import modeluno from '../Images/Principal/planta1.jpg';






import lasercut from '../Images/trumatic3.jpg';
import AOS from 'aos';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

import { Banner } from "flowbite-react";
import { HiArrowRight, HiX } from "react-icons/hi";
import { MdOutlineShapeLine } from "react-icons/md";

const features = [
  {
    name: 'Corte laser lamina, placa y tubo',
    service: 'Trumatic L 3050',
    service2: 'Trumpf Tube 5000',
    service3: 'Grua viajera SEMSA'
  },
  {
    name: 'Robot para soldadura MIG',
    service: 'Celula  de soldadura robotizada MOTOFIL'
  },
  {
    name: 'Pintura electrostatica',
    service: 'Tren de pintura electrostatica EUROIMPIANTI',
  },
  {
    name: 'Madera, corte, enchapado y maquinado',
    service: 'Seccionadora GABBIANI PT 80',
    service2: 'Enchapadora SCM STEFANI MD',
    service3: 'CNC MORBIDELLI M100F'
  },
]

export default function Example() {

  useEffect(() => {
    AOS.init({ duration: 2800 })
  })
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 400,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className='w-full'>

        <div className="mt-5" >

          <div className='py-6 bg-gray-600 rounded-tr-3xl text-center' data-aos="zoom-in">
            <h1 className='tracking-widest text-2xl md:text-4xl font-semibold text-gray-300 font-AileronsThin font normal-case'>
              Nuestros Servicios
            </h1>
          </div>
          <div className='grid grid-cols-4 md:grid-cols-8 w-full'>
            <div className=' w-full md:col-span-6 col-span-4'>
              <div className='w-full grid grid-cols-1 md:relative  md:h-full md:grid-cols-4 md:py-10 '>
                <img
                  src={modeluno}
                  alt=""
                  data-aos="zoom-in"
                  className=" col-span-1 md:absolute opacizty-100 rounded md:inset-0 md:-z-10 h-full w-full object-cover object-right md:object-center"
                />
                <div className='md:grid md:col-span-5 h-auto w-full md:pb-16 md:pt-16'>
                  <div className='md:grid md:grid-cols-8 md:px-5 md:pt-15'>

                    <div className='row-start-2 col-span-2  justify-between my-auto border-t border-gray-600 p-1' data-aos="zoom-in">
                      <div className='bg-white shadow-lg hover:bg-gray-700 hover:text-white md:shadow-gray-700 rounded-md '>
                        <div className=' border-b border-gray-300 ml-5 mr-5'>
                          <h2 className=' text-lg text-center pt-3 pb-3 py-auto '>
                            CORTE LASER LAMINA, PLACA Y TUBO
                          </h2>
                        </div>
                        <Banner>
                          <div className="flex w-full  border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                            <div className="mx-auto w-full">
                              <p className=" text-sm font-normal text-gray-500 dark:text-gray-400">
                                {/* <span className="mr-1 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 p-1 dark:bg-gray-600">
                        <MdOutlineShapeLine className="h-4 w-4" />
                      </span> */}
                                <span className="">
                                  Trumatic L3050
                                </span>
                                <a
                                  href="/trumaticl3050"
                                  className=" text-left text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                > <br />
                                  Saber mas
                                </a>
                              </p>
                            </div>
                          </div>
                        </Banner>
                        <Banner>
                          <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                            <div className="mx-auto w-full ">
                              <p className=" text-sm font-normal text-gray-500 dark:text-gray-400">

                                <span className="[&_p]:inline">
                                  Trumpf Tube 5000&nbsp;
                                </span>
                                <br />
                                <a
                                  href="/trumaticl3050"
                                  className="text-left text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                >
                                  Saber mas

                                </a>
                              </p>
                            </div>
                          </div>
                        </Banner>
                        {/* <Banner>
                          <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                            <div className="mx-auto w-full">
                              <p className=" text-justify text-sm font-normal text-gray-500 dark:text-gray-400">

                                <span className="[&_p]:inline">
                                  Grua Viajera Semsa&nbsp;
                                </span>
                                <br />
                                <a
                                  href="/trumaticl3050"
                                  className=" text-left text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500 "
                                >
                                  Saber mas

                                </a>
                              </p>
                            </div>
                          </div>
                        </Banner> */}
                        <div>

                        </div>
                      </div>
                    </div>
                    <div className='col-span-2 col-start-3 justify-between my-auto border-t border-gray-400 p-1' data-aos="zoom-in">
                      <div className='bg-white shadow-lg md:shadow-gray-700 rounded-md text-sm text-center hover:bg-gray-700 hover:text-white' >
                        <div className='border-b border-gray-300 ml-5 mr-5'>
                          <h2 className='text-lg text-center pt-3 pb-3'>

                            ROBOT PARA SOLDADURA MIG/MAG
                          </h2>
                        </div>

                        <Banner>
                          <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                            <div className="mx-auto w-full">
                              <p className=" text-left text-sm font-normal text-gray-500 dark:text-gray-400">
                                {/* <span className="mr-3 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 p-1 dark:bg-gray-600">
                        <MdOutlineShapeLine className="h-4 w-4" />
                      </span>alta */}
                                <span className=" text-left">
                                  Celula de soldadura robotizada Motofil&nbsp;
                                </span>
                                <br />
                                <a
                                  href="/trumaticl3050"
                                  className="text-left text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                >
                                  Saber mas

                                </a>
                              </p>
                            </div>
                          </div>
                        </Banner>
                      </div>
                    </div>
                    <div className='col-span-2 col-start-7 justify-between my-auto border-t border-gray-400 p-1' data-aos="zoom-in">
                      <div className='bg-white shadow-xl md:shadow-gray-700 rounded-md text-sm text-center hover:bg-gray-700 hover:text-white'>
                        <div className='border-b border-gray-300 ml-5 mr-5'>
                          <h2 className='text-lg text-center pt-3 pb-3'>
                            PINTURA ELECTROSTATICA
                          </h2>
                        </div>

                        <Banner>
                          <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                            <div className="mx-auto w-full">
                              <p className=" text-left text-sm font-normal text-gray-500 dark:text-gray-400">
                                <span className=" text-left">
                                  Tren de pintura electrostatica Euroimpianti&nbsp;
                                </span>
                                <br />
                                <a
                                  href="/trumaticl3050"
                                  className=" text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500 "
                                >
                                  Saber mas

                                </a>
                              </p>
                            </div>
                          </div>
                        </Banner>

                      </div>
                    </div>
                    <div className='row-start-2 col-start-5 col-span-2 justify-between  border-t border-gray-400 p-1' data-aos="zoom-in">
                      <div className='bg-white shadow-xl md:shadow-gray-700 rounded-md text-sm text-center hover:bg-gray-700 hover:text-white'>
                        <div className='border-b border-gray-300 ml-5 mr-5'>
                          <h2 className='text-lg text-center pt-2 pb-2 py-auto px-4'>
                            MADERA, CORTE, ENCHAPADO Y MAQUINADO
                          </h2>
                        </div>

                        <Banner>
                          <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                            <div className="mx-auto w-full">
                              <p className=" text-left text-sm font-normal text-gray-500 dark:text-gray-400">
                                {/* <span className="mr-3 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 p-1 dark:bg-gray-600">
                        <MdOutlineShapeLine className="h-4 w-4" />
                      </span> */}
                                <span className="text-left">
                                  Seccionadora Gabbiani PT 80&nbsp;
                                </span>
                                <br />
                                <a
                                  href="/trumaticl3050"
                                  className="text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500 "
                                >
                                  Saber mas

                                </a>
                              </p>
                            </div>
                          </div>
                        </Banner>
                        <Banner>
                          <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                            <div className="mx-auto w-full">
                              <p className="text-left text-sm font-normal text-gray-500 dark:text-gray-400">
                                {/* <span className="mr-3 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 p-1 dark:bg-gray-600">
                        <MdOutlineShapeLine className="h-4 w-4" />
                      </span> */}
                                <span className="text-left">
                                  Enchapadora SCM Stefani MD&nbsp;
                                </span>
                                <br />
                                <a
                                  href="/trumaticl3050"
                                  className="text-left font-medium text-cyan-600 hover:underline dark:text-cyan-500 "
                                >
                                  Saber mas

                                </a>
                              </p>
                            </div>
                          </div>
                        </Banner>
                        <Banner>
                          <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                            <div className="mx-auto w-full">
                              <p className=" text-left text-sm font-normal text-gray-500 dark:text-gray-400">
                                {/* <span className="mr-3 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 p-1 dark:bg-gray-600">
                        <MdOutlineShapeLine className="h-4 w-4" />
                      </span> */}
                                <span className="text-left">
                                  CNC Morbidelli M100F&nbsp;
                                </span>
                                <br />
                                <a
                                  href="/trumaticl3050"
                                  className=" text-left text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                >
                                  Saber mas

                                </a>
                              </p>
                            </div>
                          </div>
                        </Banner>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className='col-span-4  shadow-xl shadow-grays-50 py-10 md:shadow-none md:col-span-2 md:my-auto ' data-aos='zoom-in'>
              <div className='mb-10'>
                <h2 className='text-center text-2xl font-AileronsLight font-medium pl-3 '>
                  Excelencia forjada en cada detalle
                </h2>
              </div>

              <div className='w-auto h-auto'>
                <p className=' font-AileronsThin text-justify text-xl md:text-xl font-medium px-8'>
                  MGM 24 es una empresa que surge derivada de las necesidades actuales del sector industrial y manufacturero.

                  Somos especialistas en la producción a baja y gran escala de piezas de metal y madera de acuerdo a los requerimientos de cada proyecto.

                  Nos gusta mantener relaciones a largo plazo con nuestros clientes, basándonos en nuestro servicio de calidad.
                </p>
              </div>

            </div>
          </div>

        </div>

        
        {/* <div className='w-5/6 mx-auto'>

          <h2 className=' text-center text-2xl py-10'>

            Procesos en madera
          </h2>
          <Slider {...settings}>
            <div className=' ' >
              <img src={portafolio1} className='rounded   px-4 hover:px-0' />
            </div>
            <div className=' '>
              <img src={portafolio2} className='rounded   px-4 hover:px-0' />
            </div>
            <div className=' '>
              <img src={portafolio5} className='rounded   px-4 hover:px-0' />
            </div>

            <div className=''>
              <img src={portafolio1} className='rounded   px-4 hover:px-0' />
            </div>
            <div className=''>
              <img src={portafolio5} className='rounded   px-4 hover:px-0' />
            </div>
            <div className=''>
              <img src={portafolio6} className='rounded   px-4 hover:px-0' />
            </div>
          </Slider>
          

        </div> */}
      </div >

    </>

  )
}