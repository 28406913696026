import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "Bienvenido": "Welcome to",
          "NOSOTROS": "US",
          "SERVICIOS": "SERVICES",
          "PORTAFOLIO": "BRIEFCASE",
          "CONTACTO":"CONTACT",

          //Header - NavBar
          "Service1": "LASER CUTTING SHEET, PLATE AND TUBE",
          "Service2": "ROBOT FOR MIG/MAG WELDING",
          "Service3": "ELECTROSTATIC PAINT",
          "Service4":"WOOD, CUTTING, VENEERING AND MACHINING",
          "Idioma": "Language",



          "Excelencia":"Excellence",
          "Experiencia":"Experience",
          "Compromiso y calidad":"Commitment and quality",
          "Oferta internacional":"International Offer",
          "Servicio integral": "Integral service",
          "Slider1":"We have a 12,000 m2 plant with state-of-the-art machinery and technology specific for each project.",
          "Slider2":"We have more than 10 years of experience in the retail, contract and industrial sectors",
          "Slider3":"We maintain a high level of customer satisfaction through on-time delivery and excellent quality in each project.",
          "Slider4":"We have the capacity to solve the needs of clients at a national and global level",
          "Slider5":"We integrate our services, we offer finished, assembled products with the possibility of installation and delivery",
          // Agrega aquí más cadenas de texto en inglés
        }
      },
      es: {
        translation: {
          "Bienvenido":"Bienvenido a",
          "NOSOTROS": "NOSOTROS",
          "SERVICIOS":"SERVICIOS",
          "PORTAFOLIO":"PORTAFOLIO",
          "CONTACTO":"CONTACTO",

          //Cabezal - Navbar
          "Service1":"CORTE LASER LAMINA, PLACA Y TUBO",
          "Service2":"ROBOT PARA SOLDADURA MIG/MAG",
          "Service3":"PINTURA ELECTROSTATICA",
          "Service4":"MADERA, CORTE, ENCHAPADO Y MAQUINADO",
          "Idioma": "Idioma",

          "Excelencia":"Excelencia",
          "Experiencia":"Experiencia",
          "Compromiso y calidad":"Compromiso y calidad",
          "Oferta internacional":"Oferta internacional",
          "Servicio integral":"Servicio integral",
          "Slider1":"Contamos con una planta de 12,000 m2 con maquinaria y tecnología de punta específica para cada proyecto",
          "Slider2":"Contamos con más de 10 años de experiencia en los sectores retail, contract e industrial",
          "Slider3":"Mantenemos un alto nivel de satisfacción de nuestros clientes mediante la entrega a tiempo y una excelente calidad en cada proyecto",
          "Slider4":"Tenemos la capacidad para solucionar las necesidades de clientes a nivel nacional y mundial",
          "Slider5":"Integramos nuestros servicios, ofrecemos productos terminados, ensamblados y con posibilidad a instalación y entrega",
          // Agrega aquí más cadenas de texto en español
        }
      }
    },
    lng: "es",
    fallbackLng: "es",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
