import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TextSlider = () => {
  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(0);
  
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const textArray = [t('Excelencia'), t('Experiencia'), t('Compromiso y calidad'), t('Oferta internacional'), t('Servicio integral')];
  const textArray2 = [t('Slider1'),t('Slider2'),t('Slider3'),t('Slider4'),t('Slider5')];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % textArray.length);
      setIndex2((prevIndex) => (prevIndex + 1) % textArray2.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex items-center justify-center">
      <div className=" text-white">
        
        <h1 className=" text-lg md:text-4xl font-AileronsThin font-bold tracking-widest">{textArray[index]}</h1>
        <h1 className=" text-md md:text-2xl font-AileronsThin">{textArray2[index]}</h1>
      </div>
    </div>
  );
};

export default TextSlider;
