import { Carousel } from 'flowbite-react';
import robot from '../../Images/Robot/robot.jpg'
import robot2 from '../../Images/Robot/robot2.jpg'
import robot3 from '../../Images/Robot/robot3.jpg'
import robot4 from '../../Images/Robot/robot4.jpg'
import corte1 from '../../Images/laser/corte1.jpg'
import laserback from '../../Images/laser/laser.jpeg'
import corte2 from '../../Images/laser/corte2.jpg'
import Ccontact from '../Cards/Ccontact';
import { Banner } from 'flowbite-react';
import React, { useState, useRef, useEffect } from 'react';
import { SquaresPlusIcon, ArrowLongRightIcon, ArrowLongLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';


export default function ServicioUno() {
  
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOne, setIsOpenOne] = useState(false);
  const [mostrarContenido, setMostrarContenido] = useState(false);
  const [laserlamina, setLaserLamina] = useState(false);

  const modalRef = useRef();

  const manejarClick = () => {
    setMostrarContenido(!mostrarContenido);
  };
  const LaserClick = () => {
    setLaserLamina(!laserlamina);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeModalOne = () => {
    setIsOpenOne(false);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
      closeModalOne();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (

    <>
      <div className="relative isolate overflow-hidden bg-gray-900 py-20 sm:pt-56 sm:pb-48 ">
        <img
          src={laserback}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="w-1/2 h-full mx-10 lg:px-10 bg-black bg-opacity-5">
          <div className="mx-auto pl max-w-3xl lg:mx-0">
            <h3 className="text-2xl font-bold tracking-tight text-white sm:text-4xl font-AileronsThin">
              ROBOT PARA SOLDADURA MIG/MAG
            </h3>
            {/* <p className="mt-6 text-lg px-10 leading-8 text-justify text-gray-100 text-pretty font-AileronsThin">
              Realizamos estructuras y mobiliario eligiendo cuidadosamente los materiales y las mejores técnicas. Formas, volúmenes, colores, etc. Es el resultado de la estrecha colaboración con los equipos de diseño.
            </p> */}
          </div>

        </div>
      </div>

      <div className='md:mb-10'>
        <div className=' w-11/12 mx-auto pt-5 mt-5 rounded-xl'>

          <div className='grid md:grid-cols-8 w-full' >
            <div className='md:col-span-6 h-40 md:h-96'>
              <Carousel>
                <img src={robot} />
                <img src={robot2} />
                <img src={robot3} />
                <img src={robot4} />
              </Carousel>
            </div>
            <div className='md:relative md:col-span-2 md:flex md:items-stretch'>
              <h1 className='md:absolute w-full font-AileronsThin text-2xl font-semibold text-center py-4'>
                Robot para soldadura MIG/MAG
              </h1>

              <div className='md:absolute self-end w-full md:w-10/12 bg-gray-600  rounded-br-xl p-2 bottom-0 right-0' >
                <button onClick={() => LaserClick()} className=' text-white text-center'>
                  {laserlamina ? <ArrowRightIcon className="h-5 w-8 sm:h-6 sm:w-15" /> :

                    'Detalles tecnicos'
                  }
                </button>


              </div>

            </div>

          </div>




        </div>
        {laserlamina && (
          <div className=' relative mt-5 w-11/12 mx-auto shadow shadow-gray-500 p-5 rounded'>

            <div class="ml-5 md:ml-5 font-AileronsThin font-semibold text-lg mt-5 mb-2">
              Especificaciones tecnicas
            </div>
            <div class="px-4 py-0">

              <Banner>
                <div className=" justify-between border-t border-b border-gray-200 p-1">
                  <div className='grid grid-cols-1 md:grid-cols-2 mt-5 mb-5'>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Potencia del láser: 6 kW
                        </span>
                      </p>
                    </div>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Área de trabajo (X x Y x Z): 3000 x 1500 x 115 mm
                        </span>
                      </p>
                    </div>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Espesor máximo de acero a cortar: 25 mm
                        </span>
                      </p>
                    </div>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Espesor máximo de acero inoxidable cortado: 20 mm
                        </span>
                      </p>
                    </div>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Espesor máximo del aluminio cortado: 12 mm
                        </span>
                      </p>
                    </div>
                    {/* <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Carga eléctrica conectada: 105 kVA
                        </span>
                      </p>
                    </div> */}
                    {/* <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Dimensiones de la máquina (largo x ancho x alto): 11100 x 4600 x 2400 mm
                        </span>
                      </p>
                    </div> */}
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Peso maximo de la pieza: 900 kg
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Banner>


            </div>
          </div>
        )}

        {/* <div className=' w-11/12 mx-auto pt-5 mt-5 rounded-xl'>

          <div className='grid md:grid-cols-8 ' >

            <div className='md:col-span-6 h-40 md:h-96'>
              <Carousel>
                <img src={Tube2} />
                <img src={Tube} />
                <img src={corte1} />
                <img src={corte2} />
              </Carousel>
            </div>
            <div className='md:relative md:col-span-2 md:flex md:items-stretch flex justify-center  '>
              <h1
                className=' md:absolute w-full font-AileronsThin text-2xl font-semibold text-center py-4 '>Corte laser tubo</h1>

              
              <div className=' md:absolute self-end w-full md:w-10/12 bg-gray-600  rounded-br-xl p-2 bottom-0 right-0 ' >
                <button onClick={() => manejarClick()} className='item-center text-white text-center'>
                  {mostrarContenido ? <ArrowRightIcon className="h-5 w-8 sm:h-6 sm:w-15" /> :

                    'Detalles tecnicos'
                  }
                </button>

              </div>


            </div>


          </div>
        </div>
        {mostrarContenido && (
          <div className=' relative mt-5 w-11/12 mx-auto shadow shadow-gray-500 p-5 rounded'>

            <div class="ml-5 md:ml-5 font-AileronsThin font-semibold text-lg mt-5 mb-2">
              Especificaciones tecnicas
            </div>
            <div class="px-4 py-0">

              <Banner>
                <div className=" justify-between border-t border-b border-gray-200 p-1">
                  <div className='grid grid-cols-1 md:grid-cols-2 mt-5 mb-5'>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Potencia del láser: 3.2 kW
                        </span>
                      </p>
                    </div>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Área de trabajo( X x Y x Z): 6500 x 200 x 135 mm
                        </span>
                      </p>
                    </div>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Espesor máximo de acero a cortar: 25 mm
                        </span>
                      </p>
                    </div>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Espesor máximo de acero inoxidable cortado: 20 mm
                        </span>
                      </p>
                    </div>
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Espesor máximo del aluminio cortado: 12 mm
                        </span>
                      </p>
                    </div>
                    
                    <div className="ml-0 mx-auto  items-center">
                      <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                        <span className="mr-3 inline-flex h-100 w-10 items-center justify-center rounded-full bg-gray-250 p-1 dark:bg-gray-400">
                          <SquaresPlusIcon className="h-5 w-8 sm:h-6 sm:w-15" />
                        </span>
                        <span className=" [&_p]:inline">
                          Peso máximo de la pieza: 120 kg
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Banner>


            </div>
          </div>
        )} */}
      </div>



    </>

  )
}


